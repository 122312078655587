import { Box, Typography, Stack, Alert } from "@mui/material";

import { useGetQuotes } from "../../../../services/QuoteServices";

const NoQuotes = () => {
  const { failedQuotes } = useGetQuotes();
  console.log(failedQuotes);
  if (!failedQuotes.length) return null;
  return (
    <>
      <Box
        width={"100%"}
        sx={{
          background: "#FFFFFF",
          border: "1px dashed #BCBCBC",
        }}
        p={2}
      >
        <Stack
          mx={"auto"}
          alignItems={"center"}
          spacing={2}
          maxWidth={"80%"}
          sx={{
            "@media (max-width: 768px)": {
              maxWidth: "100%",
            },
          }}
        >
          <Typography
            variant='subtitle1'
            sx={{
              color: "var(--tertiary-color)",
              fontSize: "20px",
              fontWeight: "600",
              "@media (max-width: 768px)": {
                fontSize: "16px",
              },
            }}
          >
            We did not get quotes from the following insurer
          </Typography>
          {failedQuotes.map((item) => (
            <>
              <Alert
                severity='error'
                sx={{
                  width: "100%",
                  alignItems: "center",
                  "& .MuiAlert-message": {
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                  },
                  "@media (max-width: 768px)": {
                    "& .MuiAlert-message": {
                      gap: "8px",
                    },
                    "& img": {
                      height: "20px !important",
                    },
                  },
                }}
              >
                <img
                  style={{
                    height: "40px",
                    width: "auto",
                  }}
                  src={item.product_data?.LOGO_PATH}
                  alt='logo'
                />

                <Typography
                  sx={{
                    "@media (max-width: 768px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {item.message}
                </Typography>
              </Alert>
            </>
          ))}
          <Typography
            variant='subtitle1'
            align='center'
            sx={{
              "@media (max-width: 768px)": {
                fontSize: "12px",
              },
            }}
          >
            your location or vehicle model/age is not serviced by the insurer OR
            insurer is not rechable to provide live quotes currently{" "}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default NoQuotes;
