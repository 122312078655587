import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StyledButton } from "../../../../components/Button/Button";
import TextButton from "../../../../components/TextButton/TextButton";
import StyledInput from "../../../../components/TextInput/TextInput";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import {
  closePopups,
  showVehicleDetails,
  showVehicleExpiryDetails,
} from "../../../../modules";
import {
  Form,
  FormHeading,
  NoCarNumberButtonsWrapper,
  SubText,
} from "./LandingForm.style";

const LandingForm = () => {
  const { handleSubmit, handleChange } = useFormik({
    initialValues: { regNo: "" },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const { setUrlQuery, getUrlQuery } = useUrlQuery();
  const stage = getUrlQuery("stage");

  useEffect(() => {
    if (stage === "VEHICLE") {
      dispatch(showVehicleDetails());
    } else if (stage === "LEAD") {
      dispatch(showVehicleExpiryDetails());
    } else dispatch(closePopups());
  }, [stage]);

  const oldBikeListener = () => {
    // dispatch(showVehicleDetails());
    setUrlQuery("stage", "VEHICLE");
    setUrlQuery("flow", "all", { replace: true });
  };
  const newBikeListener = () => {
    // dispatch(showVehicleDetails());
    setUrlQuery("stage", "VEHICLE");
    setUrlQuery("flow", "new", { replace: true });
  };

  const dispatch = useDispatch();
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormHeading>
          Safeguard your bike in <span>5</span> minutes
        </FormHeading>
        <SubText>Discuss all your questions with our experts</SubText>
        <StyledInput
          sx={{
            mb: {
              xs: 3,
              sm: 3,
              md: 0,
            },
          }}
          label='Registration Number'
          colored
          onChange={handleChange}
        />
        <NoCarNumberButtonsWrapper>
          <TextButton color='primary' onClick={oldBikeListener}>
            Don't know your bike number?
          </TextButton>
          <TextButton color='tertiary' onClick={newBikeListener}>
            Got a new bike?
          </TextButton>
        </NoCarNumberButtonsWrapper>
        <StyledButton variant='contained' fullWidth enlarged>
          View Quotes
        </StyledButton>
        <TextButton className='mt-3' color='tertiary' fullWidth>
          Renew existing policy
        </TextButton>
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
            },
            mt: 1,
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <StyledButton variant='outlined' fullWidth onClick={oldBikeListener}>
            Don't know your bike number?
          </StyledButton>
          <StyledButton variant='outlined' fullWidth onClick={newBikeListener}>
            Got a new bike?
          </StyledButton>
        </Box>
      </Form>
    </>
  );
};

export default LandingForm;
