import styled from "@emotion/styled";
import { OutlinedTextFieldProps, TextField } from "@mui/material";

interface StyledInputProps extends Partial<OutlinedTextFieldProps> {
  colored?: boolean;
  enlarge?: boolean;
  label?: string;
  fixedPart?: string;
}
const CustomInput = styled(TextField)<StyledInputProps>(
  ({ size, colored, enlarge, label }) => ({
    // The text should have primary color
    ...(colored && {
      ".Mui-focused": {
        color: "var(--primary-color)",
      },
    }),
    ...(size === "small" && {
      ".MuiOutlinedInput-root": {
        height: "50px",
      },
      ".MuiInputLabel-root": {
        top: "3px",
      },
      ".MuiInputLabel-shrink": {
        top: "-1px",
      },
    }),
    // For large input components
    ...(enlarge && {
      ".MuiOutlinedInput-root": {
        height: "70px",
        fontSize: "22px",
      },
      ".MuiInputLabel-root": {
        fontSize: "22px",
        top: "3px",
      },
      ".MuiInputLabel-shrink": {
        top: "-3px",
      },
    }),
  })
);
const StyledInput = ({ ...props }: StyledInputProps) => {
  return (
    <CustomInput
      onBlur={(e) => {
        props.onBlur && props.onBlur(e);
        if (props.fixedPart) {
          if (e.target.value.indexOf(props.fixedPart) !== 0) {
            e.preventDefault();
            e.target.value = props.fixedPart;
          }
        }
      }}
      onKeyUp={(e) => {
        props.onKeyUp && props.onKeyUp(e);
        if (props.fixedPart) {
          //@ts-ignore
          if (e.target.value.indexOf(props.fixedPart) !== 0) {
            e.preventDefault();
            //@ts-ignore
            e.target.value = props.fixedPart;
          }
        }
      }}
      fullWidth
      {...props}
    />
  );
};
export default StyledInput;
