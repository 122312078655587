import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useGetProposalDetailsQuery } from "../../services/ProposalServices";
import CommunicationDetails from "./components/forms/CommunicationDetails";

import OwnerDetails from "./components/forms/OwnerDetails";
import PersonalDetails from "./components/forms/PersonalDetails";
import PlanCard from "./components/PlanCard";
import VehicleDetails from "./components/forms/VehicleDetails";
import DigitCKYCDetails from "./components/forms/DigitCKYCDetails";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const ProposalPage = () => {
  const { isProposalLoading, product_data } = useGetProposalDetailsQuery();
  const { activeForm } = useTypedSelector((state) => state.proposal);

  console.log({ activeForm });
  if (isProposalLoading) return null;
  return (
    <>
      <Grid container>
        <Grid item md={3} sm={12} xs={12}>
          <PlanCard />
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <OwnerDetails />
          <PersonalDetails />
          <CommunicationDetails />
          <VehicleDetails />
          {product_data?.INSURANCE_SLUG === "godigit" && (
            <>
              <DigitCKYCDetails />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ProposalPage;
