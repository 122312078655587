import { Grid, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "../../../../components/Modal/Modal";
import StyledTab from "../../../../components/Tabs/StyledTab";
import TabPanel from "../../../../components/Tabs/TabPanel";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { closeSeeDetails } from "../../../../modules/quotes.slice";
import CashlessGarages from "./CashlessGarages";
import PlanSummary from "./PlanSummary";
import PremiumBreakup from "./PremiumBreakup";
const KnowMore: React.FC = () => {
  const { isOpen, quoteData } = useTypedSelector(
    (state) => state.quote.see_details
  );
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeSeeDetails());
  };
  const [value, setValue] = useState<number | boolean>(0);

  const handleChange = (e: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  console.log(value);
  return (
    <Modal maxWidth='md' open={isOpen} onClose={onClose} title='Policy Details'>
      <Grid container>
        <Grid item md={3}>
          <PlanSummary />
        </Grid>
        <Grid item md={9}>
          <Tabs value={value} variant={"fullWidth"} onChange={handleChange}>
            <StyledTab label='Premium Breakup' />
            {/* <StyledTab label='Plan Details' />*/}
            {quoteData?.premium_data?.PREMIUM_DATA.IS_GARAGES_AVAILABLE ===
              "Y" && <StyledTab label='Cashless Garages' />}
          </Tabs>
          <div>
            <TabPanel value={value} index={0}>
              <PremiumBreakup />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CashlessGarages />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default KnowMore;
