import React from "react";

type price = string | undefined | number;
export const commaSeparatedPrice = (...prices: price[]) => {
  if (!prices || !prices.length) return "0";
  const sum = prices.reduce((acc, curr) => {
    return Number(acc) + Number(curr || 0);
  }, 0);
  return "₹ " + Math.floor(Number(sum)).toLocaleString("en-IN");
};

export const onInputName = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
  e.target.value =
    e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
};
export const lowercase = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.toLowerCase();
};
export const onInputPhone = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};
export const uppercase = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.toUpperCase();
};

export const startsWithNum = (v: string) => {
  if (!v) return true;

  let match = v.match(/\d/);
  return match && match.index === 0;
};
export const startsWithAlpha = (v: string) => {
  if (!v) return false;
  let match = v.match(/[A-Za-z]/);
  return match && match.index === 0;
};
export const indexNum = (v: string) => {
  if (!v) return 0;

  let match = v.match(/\d/);
  return match && match.index;
};
export const indexAlpha = (v: string) => {
  if (!v) return true;
  return v.match(/\d/)?.index === 0;
};
export const allowRegistrationNumber = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  e.preventDefault();
  let input = e.target.value;
  let splitInput = input.split("-");
  console.log(splitInput);
  let values = splitInput.map(function (v: string, i: number) {
    if (i === 0) {
      if (v.length > 2) return v.substr(0, 2);
      return v.replace(/[^A-Za-z]/g, "").toUpperCase();
    }
    if (i === 1) {
      if (v.length > 2) return v.substr(0, 2);
      return v.replace(/[^0-9]/g, "").toUpperCase();
    }
    if (i === 2 && startsWithNum(v)) {
      if (v.length > 4) return v.substr(0, 4);
      return v.replace(/[^0-9]/g, "");
    }
    if (i === 2 && startsWithAlpha(v)) {
      const index = indexNum(v);
      if (!index) return v.toUpperCase();
      if (index < 1 && index) return v.substr(0, 1);
      return v.toUpperCase();
    }
    if (i === 3) {
      if (v.length >= 4) return v.substr(0, 4);
      return v.replace(/[^0-9]/g, "");
    }
    return v;
  });
  let output = values.map((v: string, i: number) => {
    const index = Number(indexNum(v));
    if ((i === 0 || i === 1) && v.length === 2) return v + "-";
    if (i === 2 && startsWithAlpha(v)) {
      if (v.length === 3 && !index) return v + "-";
      if (index >= 1) {
        return v.slice(0, index) + "-" + v.slice(index);
      }
      if (values[3]) {
        return v + "-";
      }
    }
    if (i === 3 && v) return v;
    return v;
  });
  console.log(output);
  e.target.value = output.join("");
};

export const noSpecialExceptHyphen = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\-]/g, "");
};

export function checkAlphanumeric(str: string | undefined) {
  if (!str) return false;
  const atleastOneNumber = /\d/;
  return atleastOneNumber.test(str);
}
