import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal/Modal";
import StyledSelect from "../../../../../components/Select/Select";
import StyledInput from "../../../../../components/TextInput/TextInput";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import {
  closeExistingPolicyDetailsPopup,
  editExistingPolicyDetails,
} from "../../../../../modules/quotes.slice";
import DatePicker from "./../../../../../components/DatePicker/DatePicker";
import DetailsCard from "../DetailsCard";
import { PolicyDetailsWrapper, InputWrapper } from "../EditDetails.style";
import moment from "moment";
import {
  Box,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { StyledButton } from "../../../../../components/Button/Button";
import * as yup from "yup";
import {
  useFilterMutation,
  useGetEligibleProductsQuery,
} from "../../../../../services/CarServices";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import _ from "lodash";
interface ExistingPolicyDetailsValues {
  existing_exp_date: string | null;
  manf_month: string | null;
  prev_policy_type: string;
  registration_date: string | null;
  is_claim_made_last_year: string;
  prev_ncb: string | number;
}
const ExistingPolicyDetails = () => {
  const { showEditExistingPolicyDetails } = useTypedSelector(
    (state) => state.quote
  );
  const { requestData } = useGetEligibleProductsQuery();
  const { updateFilter, isUpdatingFilter } = useFilterMutation();
  const { setUrlQuery } = useUrlQuery();
  const dispatch = useDispatch();
  const {
    values,
    handleSubmit,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    setValues,
  } = useFormik<ExistingPolicyDetailsValues>({
    initialValues: {
      existing_exp_date: null,
      manf_month: null,
      prev_policy_type: "COMP",
      registration_date: null,
      is_claim_made_last_year: "N",
      prev_ncb: 20,
    },
    onSubmit: (values) => {
      //TODO prev_policy_type is not coming
      // const { prev_policy_type, ...toCompare } = values;
      if (requestData) {
        if (_.isMatch(requestData, values)) {
          dispatch(closeExistingPolicyDetailsPopup());
          return;
        }
        if (!isUpdatingFilter)
          updateFilter({
            ...requestData,
            ...values,
          });
      }
    },
    validationSchema: yup.object().shape({
      existing_exp_date: yup
        .string()
        .nullable()
        .required("Policy Expiry Date is required")
        .test("existing_exp_date", "Please enter a valid date", function (val) {
          return moment(val, "YYYY-MM-DD").isValid();
        }),
      is_claim_made_last_year: yup.string().required(),
      prev_ncb: yup.string(),
      registration_date: yup
        .string()
        .nullable()
        .required("Policy Expiry Date is required")
        .test("registration_date", "Please enter a valid date", function (val) {
          return moment(val, "YYYY-MM-DD").isValid();
        })
        .test(
          "registration_date",
          "Registration Date should be before expiry",
          function (val) {
            return moment(val, "YYYY-MM-DD").isBefore(
              moment(this.parent.existing_exp_date, "YYYY-MM-DD")
            );
          }
        ),
      manf_month: yup
        .string()
        .nullable()
        .required("Manufacture Month is required")
        .test("manf_month", "Please enter a valid date", function (val) {
          return moment(val, "YYYY-MM-DD").isValid();
        })
        .test(
          "manf_month",
          "Manufactoring Month should be before expiry",
          function (val) {
            return moment(val, "YYYY-MM-DD").isBefore(
              moment(this.parent.existing_exp_date, "YYYY-MM-DD")
            );
          }
        )
        .test(
          "manf_month",
          "Manufacturing Month should be before registration date",
          function (val) {
            return moment(val, "YYYY-MM-DD").isBefore(
              moment(this.parent.registration_date, "YYYY-MM-DD")
            );
          }
        )
        .test(
          "manf_month",
          "Manufacturing Month And Registration Date should be within 1 year",
          function (val) {
            return (
              moment(this.parent.registration_date, "YYYY-MM-DD").diff(
                moment(val, "YYYY-MM-DD"),
                "days"
              ) <= 365
            );
          }
        ),
    }),
  });
  const differenceInYears = moment().diff(
    moment(values.registration_date, "YYYY-MM-DD"),
    "years"
  );
  useEffect(() => {
    if (showEditExistingPolicyDetails)
      setUrlQuery("stage", "PF", {
        replace: true,
      });
  }, [showEditExistingPolicyDetails]);
  useEffect(() => {
    if (requestData)
      setValues({
        ...values,
        existing_exp_date: requestData?.existing_exp_date,
        manf_month: requestData?.manf_month || requestData.registration_date,
        prev_policy_type: requestData?.prev_policy_type,
        registration_date: requestData?.registration_date,
        is_claim_made_last_year: requestData?.is_claim_made_last_year,
        prev_ncb: requestData?.prev_ncb,
      });
  }, [requestData]);
  const onClose = () => dispatch(closeExistingPolicyDetailsPopup());
  const getMinPolicyExpiryDate = () => {
    const policy_expired_before_90_days =
      requestData?.policy_expired_before_90_days;
    const minPolicyExpiryDate =
      policy_expired_before_90_days === "Y"
        ? moment().subtract(1, "year")
        : moment().subtract(90, "days");
    return moment(minPolicyExpiryDate).isAfter(
      moment(values.registration_date, "YYYY-MM-DD")
    )
      ? moment(minPolicyExpiryDate)
      : moment(values.registration_date, "YYYY-MM-DD");
  };
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <>
      <DetailsCard
        title='Existing Policy Details'
        details={[
          `Policy Expiry Date: ${values.existing_exp_date}`,
          `Policy Type: ${
            values.prev_policy_type === "COMP" ? "Comprehensive" : "Third Party"
          } `,
        ]}
        onEdit={() => {
          dispatch(editExistingPolicyDetails());
        }}
      />
      <Modal
        open={showEditExistingPolicyDetails}
        maxWidth={"sm"}
        title='Edit Existing Policy Details'
        onClose={onClose}
        fullScreen={isMobile}
      >
        <PolicyDetailsWrapper>
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <Typography>Existing Policy Type</Typography>
              <RadioGroup
                name='prev_policy_type'
                sx={{
                  flexDirection: "row",
                }}
                onChange={(e) => {
                  setFieldValue("prev_policy_type", e.target.value);
                }}
                value={values.prev_policy_type}
              >
                <FormControlLabel
                  value={"COMP"}
                  control={<Radio />}
                  label='Comprehensive / SAOD'
                />
                <FormControlLabel
                  value={"TP"}
                  control={<Radio />}
                  label='Third Party'
                />
              </RadioGroup>
            </InputWrapper>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <InputWrapper>
                  <DatePicker
                    name='existing_exp_date'
                    label='Policy Expiry Date'
                    onChange={(e) => {
                      setFieldValue(
                        "existing_exp_date",
                        moment(e).format("YYYY-MM-DD")
                      );
                    }}
                    minDate={getMinPolicyExpiryDate()}
                    maxDate={moment(moment().add(90, "days"))}
                    onBlur={handleBlur}
                    value={values.existing_exp_date}
                  />
                  {errors.existing_exp_date && touched.existing_exp_date && (
                    <div className='error'>{errors.existing_exp_date}</div>
                  )}
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <InputWrapper>
                  <DatePicker
                    name='registration_date'
                    label='Registration Date'
                    onChange={(e) => {
                      setFieldValue(
                        "registration_date",
                        moment(e).format("YYYY-MM-DD")
                      );
                    }}
                    minDate={moment(values.manf_month, "YYYY-MM")}
                    onBlur={handleBlur}
                    value={values.registration_date}
                  />
                  {errors.registration_date && touched.registration_date && (
                    <div className='error'>{errors.registration_date}</div>
                  )}
                </InputWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <InputWrapper>
                  <DatePicker
                    name='manf_month'
                    label='Manufacturing Month'
                    onChange={(e) => {
                      setFieldValue(
                        "manf_month",
                        moment(e).format("YYYY-MM-DD")
                      );
                    }}
                    disableFuture
                    views={["year", "month"]}
                    onBlur={handleBlur}
                    inputFormat='MMMM YYYY'
                    value={values.manf_month}
                  />
                  {errors.manf_month && touched.manf_month && (
                    <div className='error'>{errors.manf_month}</div>
                  )}
                </InputWrapper>
              </Grid>
            </Grid>
            <InputWrapper>
              <Typography>Did you make a claim last year?</Typography>
              <RadioGroup
                name='is_claim_made_last_year'
                sx={{
                  flexDirection: "row",
                }}
                onChange={(e) => {
                  setFieldValue("is_claim_made_last_year", e.target.value);
                }}
                value={values.is_claim_made_last_year}
              >
                <FormControlLabel value={"Y"} control={<Radio />} label='Yes' />
                <FormControlLabel value={"N"} control={<Radio />} label='No' />
              </RadioGroup>
            </InputWrapper>
            {values.is_claim_made_last_year === "N" && (
              <>
                <Typography>Select Your Existing No Claim Bonus</Typography>
                <Grid container spacing={1}>
                  {[0, 20, 25, 35, 45, 50]
                    .filter((item, idx) => idx < differenceInYears)
                    .map((item) => (
                      <>
                        <Grid item xs={2} md={2} lg={2}>
                          <Box
                            onClick={() => {
                              setFieldValue("prev_ncb", item);
                            }}
                            sx={{
                              transition: "all 0.3s ease",
                              boxShadow: "rgb(0 110 144 / 4%) 0px 4px 8px",
                              color:
                                values.prev_ncb === item
                                  ? "rgb(255, 255, 255)"
                                  : "",
                              backgroundColor:
                                values.prev_ncb === item
                                  ? "var(--primary-color)"
                                  : "",
                              px: 2,
                              py: 1,
                              textAlign: "center",
                              "@media (max-width: 768px)": {
                                px: 1,
                              },
                            }}
                          >
                            {item}
                          </Box>
                        </Grid>
                      </>
                    ))}
                </Grid>
              </>
            )}
            <Grid container spacing={1} mt={1}>
              <Grid item xs={6}>
                <StyledButton
                  variant='outlined'
                  fullWidth
                  type='button'
                  onClick={onClose}
                >
                  Cancel
                </StyledButton>
              </Grid>

              <Grid item xs={6}>
                <StyledButton variant='contained' fullWidth type='submit'>
                  Apply
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </PolicyDetailsWrapper>
      </Modal>
    </>
  );
};

export default ExistingPolicyDetails;
