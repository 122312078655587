import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { commaSeparatedPrice } from "../../../../utils";
import Details, { MainDetails } from "./Details";

const PremiumBreakup = () => {
  const {
    quoteData: { premium_data, selected_addons },
  } = useTypedSelector((state) => state.quote.see_details);
  const isAddonsSelected = selected_addons.length > 0;
  const additionalPremium = selected_addons.reduce((acc, curr) => {
    return acc + Number(curr.premium);
  }, 0);
  const additionalGst = selected_addons.reduce((acc, curr) => {
    return acc + (Number(curr.premium) * 0.18) / 1.18;
  }, 0);
  const baseGst =
    +premium_data.PREMIUM_DATA.TOTAL_PREMIUM -
    +premium_data.PREMIUM_DATA.NET_PREMIUM;
  const totalGst = Math.round(baseGst + additionalGst);
  console.log(additionalGst, baseGst);
  return (
    <>
      <Paper
        sx={{
          width: "70%",
          padding: "24px 32px",
          background: "rgb(255, 255, 255)",
          boxShadow: "rgb(0 0 0 / 2%) 0px 4px 8px",
          borderRadius: "3px",
          height: "360px",
          overflowY: "auto",
          margin: "auto",
        }}
      >
        <Section title='Basic Cover'>
          <Details
            label='Basic OD Premium (A)'
            value={commaSeparatedPrice(premium_data.PREMIUM_DATA.BASIC_OD)}
          />
          <Details
            label='Basic TP Premium (B)'
            value={commaSeparatedPrice(premium_data.PREMIUM_DATA.BASIC_TP)}
          />
          {premium_data.PREMIUM_DATA?.NCB_DISCOUNT &&
            premium_data.PREMIUM_DATA?.NCB_DISCOUNT !== "0" && (
              <Details
                label={"NCB Discount (C)"}
                value={commaSeparatedPrice(
                  premium_data.PREMIUM_DATA?.NCB_DISCOUNT
                )}
              />
            )}
        </Section>
        {isAddonsSelected && (
          <>
            <Section title='Add-Ons'>
              {selected_addons.map((addon) => (
                <Details
                  label={addon.name}
                  value={commaSeparatedPrice(Number(addon.premium) / 1.18)}
                />
              ))}
            </Section>
          </>
        )}
        <Section title='Taxes'>
          <Details label='GST' value={commaSeparatedPrice(totalGst)} />
        </Section>
        <Divider />
        <MainDetails
          label='Amount Payable'
          value={commaSeparatedPrice(
            premium_data.PREMIUM_DATA.TOTAL_PREMIUM,
            additionalPremium
          )}
        />
      </Paper>
    </>
  );
};
export default PremiumBreakup;

const Section: React.FC<{
  title: string;
}> = ({ title, children }) => {
  return (
    <>
      <Box mb={4}>
        <Typography variant='h6' fontSize={"16px"} fontWeight='700'>
          {title}
        </Typography>
        <Stack>{children}</Stack>
      </Box>
    </>
  );
};
