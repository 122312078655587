import { useFormik } from "formik";
import { useEffect } from "react";
import FormCard from "../FormCard";
import * as yup from "yup";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import StyledInput from "../../../../components/TextInput/TextInput";
import { uppercase } from "../../../../utils";
import Select from "../../../../components/Select/Select";
import {
  useGetProposalDetailsQuery,
  useProposalFinalSubmitMutation,
  useUpdateProposalMutation,
} from "../../../../services/ProposalServices";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { StyledButton } from "../../../../components/Button/Button";

const EditComp: React.FC = () => {
  const { product_data, quote_data } = useGetProposalDetailsQuery();
  const { proposalData } = useTypedSelector((state) => state.proposal);

  const {
    updateProposal,
    isSubmitted,
    isSubmitting: isProposalSaving,
  } = useUpdateProposalMutation({ isFormData: true });
  const { submitProposal, isSubmitting } = useProposalFinalSubmitMutation();
  useEffect(() => {
    if (isSubmitted && !isProposalSaving) {
      submitProposal();
    }
  }, [isSubmitted, isProposalSaving]);
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues: {
      is_ckyc_no: "N",
      ckyc_no: "",
      method_of_verification: "",
      pan_no: "",
      address_proof_doc: null,
      address_proof: "",
      identity_proof_doc: null,
      identity_proof: "",
      photo_doc: null,
    },
    onSubmit: (values) => {
      updateProposal({
        ...values,
      });
    },
    validationSchema: yup.object().shape({
      is_ckyc_no: yup.mixed().required(),
      ckyc_no: yup
        .string()
        .nullable()
        .when("is_ckyc_no", {
          is: "Y",
          then: yup
            .string()
            .required("This field is required")
            .matches(/^[0-9]$/, "Please enter a valid value"),
        }),

      method_of_verification: yup
        .string()
        .nullable()
        .when("is_ckyc_no", {
          is: "N",
          then: yup.string().required("This field is required"),
        }),
      pan_no: yup
        .string()
        .nullable()
        .when(["is_ckyc_no", "method_of_verification"], {
          is: (is_ckyc_no: string, method_of_verification: string) =>
            is_ckyc_no === "N" && method_of_verification === "pan",
          then: yup
            .string()
            .required("This field is required")
            .matches(
              /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
              "Please enter a valid pan no."
            ),
        }),
      address_proof: yup.string().nullable(),
      identity_proof: yup.string().nullable(),
      address_proof_doc: yup.mixed().nullable(),
      identity_proof_doc: yup.mixed().nullable(),
      photo_doc: yup.mixed().nullable(),
    }),
  });
  useEffect(() => {
    setValues({
      ...values,
      pan_no: proposalData?.pan_no,
    });
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack
          sx={{
            mt: 2,
          }}
          spacing={2}
        >
          <Box>
            <Typography variant='subtitle1' component={"p"}>
              Do you have CKYC no. ?
            </Typography>
            <RadioGroup
              name='is_ckyc_no'
              sx={{
                flexDirection: "row",
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.is_ckyc_no}
            >
              <FormControlLabel control={<Radio />} label='Yes' value={"Y"} />
              <FormControlLabel control={<Radio />} label='No' value={"N"} />
            </RadioGroup>
          </Box>
          {values?.is_ckyc_no === "Y" && (
            <>
              <StyledInput
                label='CKYC Number'
                size={"small"}
                onChange={handleChange}
                name='ckyc_no'
                value={values.ckyc_no}
                onBlur={handleBlur}
                error={touched.ckyc_no && Boolean(errors.ckyc_no)}
                helperText={touched.ckyc_no && errors.ckyc_no}
              />
            </>
          )}
          {values?.is_ckyc_no === "N" && (
            <>
              <Select
                label='Method of verification'
                size={"small"}
                handleChange={(value) => {
                  setFieldValue("method_of_verification", value);
                }}
                selectedValue={values.method_of_verification}
                options={verification_methods || []}
                name='method_of_verification'
                onBlur={handleBlur}
                error={
                  touched.method_of_verification &&
                  Boolean(errors.method_of_verification)
                }
                helperText={
                  touched.method_of_verification &&
                  errors.method_of_verification
                }
              />
              {values?.method_of_verification === "pan" && (
                <StyledInput
                  label='PAN Number'
                  size={"small"}
                  onChange={(e) => {
                    uppercase(e);
                    handleChange(e);
                  }}
                  name='pan_no'
                  value={values.pan_no}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: 10,
                  }}
                  error={touched.pan_no && Boolean(errors.pan_no)}
                  helperText={touched.pan_no && errors.pan_no}
                />
              )}
              {values?.method_of_verification === "doc" && (
                <>
                  <Select
                    label='Address Proof'
                    size={"small"}
                    handleChange={(value) => {
                      setFieldValue("address_proof", value);
                    }}
                    selectedValue={values.address_proof}
                    options={documents || []}
                    name='address_proof'
                    onBlur={handleBlur}
                    error={
                      touched.address_proof && Boolean(errors.address_proof)
                    }
                    helperText={touched.address_proof && errors.address_proof}
                  />
                  <StyledInput
                    type='file'
                    size={"small"}
                    onChange={(e: any) => {
                      setFieldValue("address_proof_doc", e.target.files[0]);
                    }}
                    name='address_proof_doc'
                    onBlur={handleBlur}
                    error={
                      touched.address_proof_doc &&
                      Boolean(errors.address_proof_doc)
                    }
                    helperText={
                      touched.address_proof_doc && errors.address_proof_doc
                    }
                  />
                  <Select
                    label='Identity Proof'
                    size={"small"}
                    handleChange={(value) => {
                      setFieldValue("identity_proof", value);
                    }}
                    selectedValue={values.identity_proof}
                    options={documents || []}
                    name='identity_proof'
                    onBlur={handleBlur}
                    error={
                      touched.identity_proof && Boolean(errors.identity_proof)
                    }
                    helperText={touched.identity_proof && errors.identity_proof}
                  />
                  <StyledInput
                    type='file'
                    size={"small"}
                    onChange={(e: any) => {
                      setFieldValue("identity_proof_doc", e.target.files[0]);
                    }}
                    name='identity_proof_doc'
                    onKeyDown={() => {}}
                    onBlur={handleBlur}
                    error={
                      touched.identity_proof_doc &&
                      Boolean(errors.identity_proof_doc)
                    }
                    helperText={
                      touched.identity_proof_doc && errors.identity_proof_doc
                    }
                  />
                </>
              )}
            </>
          )}
          <Box>
            <Typography variant='subtitle1' component={"p"}>
              Photo
            </Typography>
            <StyledInput
              type='file'
              size={"small"}
              onChange={(e: any) => {
                setFieldValue("photo_doc", e.target.files[0]);
              }}
              name='photo_doc'
              onKeyDown={() => {}}
              onBlur={handleBlur}
              error={touched.photo_doc && Boolean(errors.photo_doc)}
              helperText={touched.photo_doc && errors.photo_doc}
            />
          </Box>
          <StyledButton type='submit' variant='contained' size='medium'>
            Proceed to Final Submit
          </StyledButton>
        </Stack>
      </form>
    </>
  );
};

const SummaryComp: React.FC = () => {
  return <></>;
};
const DigitCKYCDetails = () => {
  return (
    <>
      <FormCard
        title='CKYC Details'
        index={4}
        EditComp={EditComp}
        SummaryComp={SummaryComp}
      />
    </>
  );
};

export default DigitCKYCDetails;
const verification_methods = [
  {
    OPTION_KEY: "pan",
    OPTION_VAL: "PAN Number",
  },
  {
    OPTION_KEY: "doc",
    OPTION_VAL: "Physical Documents",
  },
];

const documents = [
  {
    OPTION_KEY: "D20",
    OPTION_VAL: "CGHS ECHS CARD",
  },
  {
    OPTION_KEY: "D21",
    OPTION_VAL: "PHOTO CREDIT CARD",
  },
  {
    OPTION_KEY: "D22",
    OPTION_VAL: "IT PROOF",
  },
  {
    OPTION_KEY: "D23",
    OPTION_VAL: "PHOTO PENSIONER CARD",
  },
  {
    OPTION_KEY: "D24",
    OPTION_VAL: "BANK STATEMENT",
  },
  {
    OPTION_KEY: "D25",
    OPTION_VAL: "AADHAAR IMAGE",
  },
  {
    OPTION_KEY: "D26",
    OPTION_VAL: "DRIVING LICENSE IMAGE",
  },
  {
    OPTION_KEY: "D27",
    OPTION_VAL: "VOTER ID IMAGE",
  },
  {
    OPTION_KEY: "D28",
    OPTION_VAL: "PASSPORT IMAGE",
  },
];
