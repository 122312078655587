import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers/";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { MomentInput, Moment } from "moment";
import { useState } from "react";
import StyledInput from "../TextInput/TextInput";
type views = "year" | "month" | "day";
interface DatePickerProps {
  label: string;
  value: string | null;
  views?: views[];
  onChange: (value: MomentInput) => void;
  minDate?: Moment;
  maxDate?: Moment;
  openTo?: views;
  name: string;
  onBlur?: (e: any) => void;
  inputFormat?: string;
  size?: "small" | "medium";
  error?: boolean | undefined;
  helperText?: string | false;
  disableFuture?: boolean;
  readOnly?: boolean;
}
const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value,
  onChange,

  ...props
}) => {
  const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        label={label}
        open={props?.readOnly ? false : open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={onChange}
        value={value ? moment(value, "YYYY-MM-DD") : null}
        {...props}
        views={props.views || ["year", "month", "day"]}
        openTo={props.openTo || "year"}
        inputFormat={props.inputFormat || "DD/MM/YYYY"}
        renderInput={(params) => {
          return (
            <TextField
              fullWidth
              variant='outlined'
              onClick={() => {
                setOpen(!open);
              }}
              sx={{
                ...(props.size === "small" && {
                  ".MuiOutlinedInput-root": {
                    height: "50px",
                  },
                  ".MuiInputLabel-root": {
                    top: "3px",
                  },
                  ".MuiInputLabel-shrink": {
                    top: "-1px",
                  },
                }),
              }}
              size={props.size}
              helperText={props.helperText}
              onBlur={props.onBlur}
              name={props.name}
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: props.readOnly,
              }}
              error={props.error}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
